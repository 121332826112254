import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public language = 'de';
  public languagePopoverOptions = {
    backdropDismiss: true,
    showBackdrop: false,
    cssClass: 'language-selector'
  };

  constructor(private translate: TranslateService) {}

  public useLanguage() {
    this.translate.use(this.language);
  }

  public changeLanguage(language: string) {
    this.language = language;
    this.translate.use(language);
  }
}
