import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../service/language.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.page.html'
})
export class HeaderPage implements OnInit {
  constructor(public languageService: LanguageService,
              public router: Router) {}

  ngOnInit() {}
}
