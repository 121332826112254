import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SimonePageRoutingModule } from './simone-routing.module';

import { SimonePage } from './simone.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SimonePageRoutingModule
  ],
  declarations: [SimonePage]
})
export class SimonePageModule {}
