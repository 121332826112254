import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FooterPageModule } from '../template-components/footer/footer.module';
import { HeaderPageModule } from '../template-components/header/header.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule],
  exports: [
    TranslateModule,
    FooterPageModule,
    HeaderPageModule,
    CommonModule,
    FormsModule,
    IonicModule,
  ],
})
export class SharedModule {}
