import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HardwareService {
  public darkMode = false;

  constructor() { }

  checkDarkMode() {
    // Use matchMedia to check the user preference
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    if (prefersDark.matches) {
      this.darkMode = true;
    }

    this.toggleDarkTheme(this.darkMode);

    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addListener((mediaQuery) =>
      this.toggleDarkTheme(mediaQuery.matches)
    );
  }

  // Add or remove the "dark" class based on if the media query matches
  toggleDarkTheme(shouldAdd: boolean | undefined) {
    document.body.classList.toggle('dark', shouldAdd);
  }
}
