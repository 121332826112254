import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simone',
  templateUrl: './simone.page.html',
  styleUrls: ['./simone.page.scss'],
})
export class SimonePage implements OnInit {
  public daysDone!: number;
  public fullDaysDone!: number;
  public percentage!: number;
  public daysInAYear: (number | boolean)[][] = [];
  public weeksInAYear: (number | boolean)[][] = [];
  public weeksDone!: number;
  public showDays = true;
  public showWeeks = false;
  private startDate!: Date;

  constructor() {}

  ngOnInit() {
    this.startDate = new Date('September 01, 2022 00:00:00');
    this.calculateCurrentDate();
    this.calculateArraysForDisplay();
  }

  public changePresentationOfSquares() {
    if (this.showDays) {
      this.showDays = false;
      this.showWeeks = true;
    } else {
      this.showDays = true;
      this.showWeeks = false;
    }
  }

  private calculateArraysForDisplay() {
    for (let day = 1; day < 366; day++) {
      if (this.daysDone >= day) {
        this.daysInAYear.push([day, true]);
      } else {
        this.daysInAYear.push([day, false]);
      }
    }

    for (let week = 1; week < 53; week++) {
      if (this.weeksDone >= week) {
        this.weeksInAYear.push([week, true]);
      } else {
        this.weeksInAYear.push([week, false]);
      }
    }
  }

  private calculateCurrentDate() {
    const now: number = new Date().getTime();
    this.daysDone = Number(
      ((now - this.startDate.getTime()) / 86400000).toFixed(2)
    );
    this.fullDaysDone = Math.floor((now - this.startDate.getTime()) / 86400000); // Because .toFixed() rounds up
    this.percentage = Number(((this.daysDone / 365) * 100).toFixed(2));
    this.weeksDone = Math.floor(this.daysDone / 7);
  }
}
