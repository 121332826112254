import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './service/language.service';
import { HardwareService } from './service/hardware.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    translate: TranslateService,
    public languageService: LanguageService,
    hardwareService: HardwareService
  ) {
    hardwareService.checkDarkMode();
    translate.setDefaultLang(this.languageService.language);
    translate.use(this.languageService.language);
  }
}
