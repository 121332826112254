import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SimonePageModule } from './pages/other/simone/simone.module';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'impressum',
    loadChildren: () =>
      import('./template-components/impressum/impressum.module').then(
        (m) => m.ImpressumPageModule
      ),
  },
  {
    path: 'recruiter',
    loadChildren: () =>
      import('./pages/recruiter/recruiter.module').then(
        (m) => m.RecruiterPageModule
      ),
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('./pages/projects/projects.module').then(
        (m) => m.ProjectsPageModule
      ),
  },
  {
    path: 'footer',
    loadChildren: () =>
      import('./template-components/footer/footer.module').then(
        (m) => m.FooterPageModule
      ),
  },
  {
    path: 'header',
    loadChildren: () =>
      import('./template-components/header/header.module').then(
        (m) => m.HeaderPageModule
      ),
  },
  {
    path: 'tiktaktoe',
    loadChildren: () =>
      import('./pages/games/tiktaktoe/tiktaktoe.module').then(
        (m) => m.TiktaktoePageModule
      ),
  },
  {
    path: 'simone',
    loadChildren: () => SimonePageModule,
  },
  {
    path: 'games',
    loadChildren: () => import('./pages/games/games.module').then( m => m.GamesPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
